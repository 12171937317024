<script>
    import Header from '@/template/header.svelte';
    import Footer from '@/template/footer.svelte';
    import SvelteMarkdown from 'svelte-markdown';

    const privacyMarkdown = `
# Content Consent Agreement

By uploading content to buymynudes.com, you ("Content Provider") agree to the following terms:

1. **Grant of License**: You grant buymynudes.com a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display the content you upload ("Content") for the purposes of operating and promoting the platform.

2. **Consent to Use**: You explicitly consent to buymynudes.com using your Content, including but not limited to images and likeness, in accordance with the license granted above.

3. **Representation**: You represent and warrant that you have the right to grant this license and consent for all Content you upload.

4. **Revocation**: You understand that while you may delete your Content from the platform at any time, you cannot revoke this consent for any Content that has already been used or distributed by buymynudes.com.

5. **Acknowledgment**: You acknowledge that this consent is separate from and in addition to the Content Provider Agreement and other Terms of Service.`;
</script>

<Header />
<main class="flex flex-col min-h-[90vh] text-black dark:text-white max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 prose">
    <SvelteMarkdown source={privacyMarkdown} />
</main>
<Footer />
